import axios from "axios";


axios.interceptors.request.use(
    config => {
        const token = process.env.REACT_APP_API_KEY;
        if (token) {
            config.headers["Authorization"] = "Api-Key " + token;
        }
        return config;
    },
    error => {
        Promise.reject(error);

    }
);

