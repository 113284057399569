import validator from 'validator'

export const validate_tax = (value, changeFieldValidated) => {
    if (value.length === 10 || value.length === 11){
        changeFieldValidated('tax_number', true);
        return value
    }
    changeFieldValidated('tax_number', false);
    return ''
}

export const validate_postal_code = (value, changeFieldValidated, fieldName) => {
    if(value.length === 6 && value[2] === '-'){
        changeFieldValidated(fieldName, true);
        return value
    }
    changeFieldValidated(fieldName, false);
    return ''
}

export const validate_email = (value, changeFieldValidated) => {
    if(validator.isEmail(value)){
        changeFieldValidated('email', true);
        return value
    }
    changeFieldValidated('email', false);
    return ''
}

export const validate_phone = (value, changeFieldValidated) => {
    if(validator.isMobilePhone(value)){
        changeFieldValidated('phone_number', true);
        return value
    }
    changeFieldValidated('phone_number', false);
    return ''
}