import {createTheme} from "@mui/material/styles";
import {plPL} from "@mui/x-date-pickers/locales";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#ffcb00'
        },
        secondary: {
            main: '#ffcb00'
        }
    },
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif',
        ].join(','),
    },
    plPL,
});