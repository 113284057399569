const PREFIXES = {
    DOCKER: "http://172.16.0.17:8000",
    DOCKER_NEW: "https://api.wagner-polska.com.pl",
    LOCAL: "http://127.0.0.1:8000",
  };

  const PREFIX = PREFIXES.DOCKER_NEW + '/api/v1';


export const API = {
    ORDER_TYPES: `${PREFIX}/order-types/`,
    NEW_ORDER: `${PREFIX}/new-service-orders/`,
    DEVICES: `${PREFIX}/devices/`,
    DEVICES_CATALOGS: `${PREFIX}/devices-catalogs/`,
    STATES: `${PREFIX}/states/`,
    COUNTRIES: `${PREFIX}/countries/`,
    GUS: `${PREFIX}/gus/`,
    SERVICE_ADDRESS: `${PREFIX}/service-address/`,
    SHIPPING_METHODS: `${PREFIX}/shipping-methods/`,

}
